import React, { ReactElement } from "react";
import MainBanner from "@components/warrantyforhouse/mainBanner/mainBanner";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import WarrantyForHouseWrapper from "@components/layouts/warrantyForHouseWrapper";

const OffersList = dynamic(() => import("@components/shared/offersList"));

import Benefits from "@components/warrantyforhouse/benefits";
import HowIsHomeWarranty from "@components/warrantyforhouse/howIsHomeWarranty";
import WhatIsHomeWarranty from "@components/warrantyforhouse/whatIsHomeWarranty";
import WhatAreBenefits from "@components/warrantyforhouse/whatAreBenefits";
import LiesBeneath from "@components/warrantyforhouse/liesBeneath";
import LeaveHome from "@components/warrantyforhouse/leaveHome";
import NewJerseyDisclaimer from "@components/shared/newJerseyDisclaimer";
export default function WarrantyForHouseHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <WarrantyForHouseWrapper>
            <MainBanner />
            <WhatIsHomeWarranty />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <HowIsHomeWarranty />
            <WhatAreBenefits />
            <LiesBeneath />
            <Benefits />
            <LeaveHome />
            <NewJerseyDisclaimer backGround="#fffaef" />
        </WarrantyForHouseWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
